import { gql, useMutation } from "@apollo/client";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Input from "@mui/material/Input";
import { useState } from "react";
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { useNavigate } from "react-router";
import { ErrorMessage } from '@hookform/error-message';


interface IFormInput {
  name: string;
  protocol: "TLS" | "TCP" | "UDP";
  nameType: "SRV" | 'A' | 'IP';
  authorizedAddresses: string;
  billingId: string;
  tenantName: string;
  maxParallelCalls: number;
}

const CREATE_TRUNK = gql`
  mutation CreateTrunk($input: CreateEndpointInput!) {
    createEndpoint(input: $input) {
      endpointId
      error {
        message
      }
    }
  }
`;


export default function CreateTrunk() {

  const { control, handleSubmit, register, formState: { errors }, } = useForm<IFormInput>()
  const [createTrunk, { loading, error }] = useMutation(CREATE_TRUNK);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = async (input) => {

    const tags = [];

    if (input.tenantName?.trim()?.length > 0) {
      tags.push({ key: 'tenantName', value: input.tenantName.trim() });
    }

    if (input.billingId?.trim()?.length > 0) {
      tags.push({ key: 'billingId', value: input.billingId.trim() });
    }

    if (input.name?.trim().length > 0) {
      tags.push({ key: 'Name', value: input.name.trim() });
    }

    await createTrunk({
      variables: {
        input: {
          transportProtocol: input.protocol,
          dnsNameType: input.nameType,
          maxConcurrentCalls: input.maxParallelCalls,
          authorizedAddresses: input.authorizedAddresses,
          tags,
        },
      },
      refetchQueries: ["ListTrunks"],
      awaitRefetchQueries: true,
    });
    // now return to the trunk list
    navigate('/trunks');
  };

  if (loading)
    return <>Submitting...</>
  if (error)
    return <>Submission error! ${error.message}</>

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }} size="small">
          <InputLabel id="name">Trunk Name</InputLabel>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              minLength: { value: 1, message: 'Trunk name is required' },
              maxLength: { value: 32, message: 'Trunk name is too long' },
              pattern: { value: /^([a-zA-Z0-9][a-zA-Z0-9-_]?)*[a-zA-Z0-9]+$/, message: 'Invalid trunk name' },
            }}
            render={({ field }) => <Input {...field} value={field.value.toLowerCase()} onChange={e => field.onChange(e.target.value.toLowerCase())} />}
          />
          <ErrorMessage errors={errors} name="name" />
        </FormControl>
      </div>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="protocol">Trunk Type</InputLabel>
          <Controller
            name="protocol"
            control={control}
            defaultValue="TLS"
            render={({ field, }) => <Select {...field}>
              <MenuItem value={'TLS'}>TLS (Secure, Preferred)</MenuItem>
              <MenuItem value={'TCP'}>TCP (Insecure)</MenuItem>
              <MenuItem value={'UDP'}>UDP (Insecure, Deprecated)</MenuItem>
            </Select>
            }
          />
        </FormControl>
      </div>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="nameType">DNS Type</InputLabel>
          <Controller
            name="nameType"
            control={control}
            defaultValue="SRV"
            render={({ field, }) => <Select {...field}>
              <MenuItem value={'SRV'}>SRV (Preferred)</MenuItem>
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'IP'}>IP</MenuItem>
            </Select>
            }
          />
        </FormControl>
      </div>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="authorizedAddresses">Authorized IP</InputLabel>
          <Controller
            name="authorizedAddresses"
            control={control}
            rules={{
              pattern: {
                message: 'Invalid IP Address',
                value: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
              },
            }}
            defaultValue=""
            render={({ field }) => <Input {...field}
              placeholder="X.X.X.X"
            />}
          />
          <ErrorMessage errors={errors} name="authorizedAddresses" />
        </FormControl>
      </div>

      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="billingId">Billing ID</InputLabel>
          <Controller
            name="billingId"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Billing ID is required',
              },
              pattern: {
                message: 'Invalid Billing ID',
                value: /^[0-9A-F]{4}-[0-9A-F]{4}$/,
              },
            }}
            render={({ field }) => <Input
              {...field}
              placeholder="XXXX-XXXX"
              value={field.value.toUpperCase().trim()}
              onChange={e => field.onChange(e.target.value.toUpperCase().trim())}
            />}
          />
          <ErrorMessage errors={errors} name="billingId" />
        </FormControl>
      </div>

      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 280 }} size="small">
          <InputLabel id="tenantName">Tenant Name</InputLabel>
          <Controller
            name="tenantName"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </div>

      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }} size="small">
          <InputLabel id="maxParallelCalls">Max Parallel Calls</InputLabel>
          <Controller
            name="maxParallelCalls"
            control={control}
            rules={{
              pattern: {
                message: 'Invalid Max Parallel Calls',
                value: /^[0-9]{1,3}$/,
              }
            }}
            render={({ field }) => <Input
              {...field}
              value={field.value ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(toInt(e.target.value))}
            />}
          />
          <ErrorMessage errors={errors} name="maxParallelCalls" />
        </FormControl>
      </div>


      <Button type="submit" onClick={handleSubmit(onSubmit)}>Create</Button>

    </form>
  );


}


function toInt(value?: string) {

  if (value === undefined) {
    return undefined;
  }

  const nval = parseInt(value);

  if (Number.isInteger(nval)) {
    return nval;
  }

  return undefined;


}