import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Checkbox, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import React, { useState } from "react";
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { ListTrunksQuery, useListTrunksQuery } from './TrunkList.generated';


function ItemMenu({ items }: { items: string[] }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const [deleteTrunk] = useMutation(DELETE_TRUNK);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    // await deleteTrunk({
    //   variables: { input: { endpointId: items[0] } },
    //   refetchQueries: ["ListTrunks"],
    //   awaitRefetchQueries: true,
    // });
  };

  return <>
    <Button
      onClick={handleClick}
      endIcon={<KeyboardArrowDown />}
      disableElevation
      disabled={items.length === 0}
      variant="contained"
    >
      Actions
    </Button>
    <Menu
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem disabled={items.length !== 1} onClick={handleDelete}>Delete Trunk</MenuItem>
    </Menu>
  </>

}

function Hidden(props: any) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" size="small" onClick={handleClickOpen}>
        Credentials
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {"Trunk Credentials"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText><b>Username</b>: <code>{props.username}</code></DialogContentText>
          <DialogContentText><b>Password</b>: <code>{props.password}</code></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function DataList({ loading, data }: { loading: boolean, data: ListTrunksQuery['endpoints']['items'] }) {

  const navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState<string[]>([])

  const availableNames : string[] = data?.map((e: any) => e.id) ?? [];

  const handleChange = React.useCallback(
    (checkedName: any) => {

      const newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter(name => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];

      const finalNames = newNames.filter(name => availableNames.includes(name));

      setCheckedValues(finalNames);

      return finalNames;


    },
    [checkedValues, availableNames]
  )

  const selectedValues = checkedValues.filter(name => availableNames.includes(name));

  if (loading) {
    return <></>;
  }

  function handleCreateTrunkClick() {
    navigate('/trunks/create');
  }

  return <>
    <Box role="presentation">
      <ButtonGroup disableElevation variant="contained">
        {/* <Button disableElevation variant="contained">
        <Refresh onClick={handleRefresh} />
      </Button> */}
        <Button onClick={handleCreateTrunkClick}>
          Create Trunk
        </Button>
        <ItemMenu items={selectedValues} />
      </ButtonGroup>
    </Box>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Tenant</TableCell>
          <TableCell>Name</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.filter((x): x is NonNullable<typeof x> => x !== null).map((e) => <TableRow key={e.id}>
          <TableCell><Checkbox onChange={() => handleChange(e.id)} checked={selectedValues.includes(e.id)} /></TableCell>
          <TableCell><ContactsCell contacts={e.contacts.items} /></TableCell>
          <TableCell>{e.name}</TableCell>
          <TableCell>{e.tenantName}</TableCell>
          <TableCell>{e.uri}</TableCell>
          <TableCell><Hidden {...e} /></TableCell>
        </TableRow>)}
      </TableBody>
    </Table >
  </>
}

function Item({ title, children }: { title: any, children: any }) {
  return <TableRow>
    <TableCell sx={{ fontWeight: 'bold' }} align="right">{title}</TableCell>
    <TableCell>{children}</TableCell>
  </TableRow>
}

function Contact({ contact, instance, regId, received, socket, userAgent, callid, cseq, version, createdAt, updatedAt, expiresAt }: any) {
  return <Table size="small">
    <TableBody>
      <Item title="Remote">{received}</Item>
      <Item title="User-Agent">{userAgent}</Item>
      <Item title="Contact">{contact}</Item>
      {instance && <Item title="Instance">{instance}</Item>}
      {regId && <Item title="Reg-ID">{regId}</Item>}
      <Item title="Socket">{socket}</Item>
      <Item title="Call-ID/Cseq">{callid}/{cseq}</Item>
      <Item title="Created"><Moment interval={1000} fromNow>{createdAt}</Moment></Item>
      <Item title="Updated"><Moment interval={1000} fromNow>{updatedAt}</Moment></Item>
      <Item title="Expires"><Moment fromNow interval={1000}>{expiresAt}</Moment></Item>
      <Item title="Version">{version}</Item>
    </TableBody>
  </Table>
}

function ContactsCell({ contacts }: { contacts: any }) {

  if (contacts.length === 0) {
    return <Typography color="red">Offline</Typography>
  }

  return <PopupState variant="popper">
    {(popupState) => (
      <div {...bindHover(popupState)} >
        <Typography color="green" sx={{ cursor: 'pointer' }}>Online</Typography>
        <Popper {...bindPopper(popupState)}>
          <Paper sx={{ p: 2 }}>
            {contacts.map((c: any) => <Contact key={c.id} {...c} />)}
          </Paper>
        </Popper>
      </div>
    )}
  </PopupState>

}

function TrunkList() {
  const { loading, error, data, } = useListTrunksQuery({
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'network-only',
  });

  if (loading) {
    return <>loading trunk data...</>
  }

  if (error || data?.endpoints?.items === undefined) {
    return <Typography color="red">Network Error: {error?.message}</Typography>
  }

  return <>
    <DataList loading={loading} data={data.endpoints.items} />
  </>

}


export default TrunkList;