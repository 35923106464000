import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { useAuth0 } from '@auth0/auth0-react';

const AuthorizedApolloProvider = ({ children }: any) => {

  //
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const httpLink = createHttpLink({
    uri: 'https://api.rtfs.cloud/graphql',
    // uri: 'http://localhost:8080/graphql',
    // batchMax: 5, // No more than 5 operations per batch
    // batchInterval: 20 // Wait no more than 20ms after first batched operation
    includeExtensions: true,
  })

  const tokenProvider = async () => {
    const params = {
      audience: 'https://rtfs.cloud/',
      scope: 'ops:manage',
    };
    try {
      return await getAccessTokenSilently(params);
    }
    catch (e: any) {
      if (e?.error === 'consent_required') {
        // this requires a popup. it may be blocked by the client
        console.log("consent required, fetching new token with popup")
        return await getAccessTokenWithPopup(params);
      }
      else {
        console.log("error getting token:", JSON.stringify(e));
        throw e;
      }
    }
  };


  const authLink = setContext(async () => {
    const token = await tokenProvider();
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 1000,
          max: Infinity,
          jitter: true
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => !!error
        }
      }),
      onError(({ graphQLErrors, networkError, operation, forward }) => {

        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            console.log("GraphQL error", err);
            switch (err.extensions.code) {
              // Apollo Server sets code to UNAUTHENTICATED
              // when an AuthenticationError is thrown in a resolver
              case "UNAUTHENTICATED":
                console.log("unauthenticated, clearing token")
                // Modify the operation context with a new token
                localStorage.removeItem('token');
                // Retry the request, returning the new observable
                return forward(operation);
            }
          }
          graphQLErrors.forEach(
            ({ message, locations, path }) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          );
        }

        if (networkError) {
          console.error(`[Network error]:`, networkError);
          if ('statusCode' in networkError && networkError?.statusCode === 401) {
            console.log("removing token");
            localStorage.removeItem('token');
          }
        }

      }),
      authLink,
      httpLink,
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};


export default AuthorizedApolloProvider;
