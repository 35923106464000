import { createRoot } from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import Moment from 'react-moment';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import RtfsAuthProvider from './RtfsAuthProvider';
import theme from './theme';

//
moment.relativeTimeThreshold('ss', 4);
Moment.globalMoment = moment;
Moment.startPooledTimer(1000);

const container = document.getElementById('root');

if (container !== null) {

  const root = createRoot(container);

  root.render(
    // <React.StrictMode>
      <BrowserRouter>
        {/* <RecoilRoot> */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RtfsAuthProvider>
            <App />
          </RtfsAuthProvider>
        </ThemeProvider>
        {/* </RecoilRoot> */}
      </BrowserRouter>
    // </React.StrictMode>,
  );

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
