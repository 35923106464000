import { Auth0Provider } from "@auth0/auth0-react";

const RtfsAuthProvider = ({ children }: { children?: React.ReactNode }) => <Auth0Provider
  domain="auth.fluentstream.com"
  clientId="L4aK2nOX4tL5O0NT6MdyvZda4Y41dQc8"
  audience="https://rtfs.cloud/"
  scope="openid ops:manage"
  redirectUri={window.location.origin}
  useRefreshTokens={true}
>
  {children}
</Auth0Provider>

export default RtfsAuthProvider;