import InboxIcon from '@mui/icons-material/FileUpload';
import { List } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps, useLocation,
  useResolvedPath
} from 'react-router-dom';

const drawerWidth = 240;

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {

  const { icon, primary, to } = props;

  const end = false;

  let location = useLocation();
  let path = useResolvedPath(to);

  let locationPathname = location.pathname;
  let toPathname = path.pathname;

  let isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");


  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
        itemProps,
        ref,
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to],
  );

  return (
    <ListItem button component={renderLink} selected={isActive}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
      {/* <Typography variant="body2" color="text.secondary">
       ⌘X
     </Typography> */}
    </ListItem>
  );
}


export default function FluentStreamDrawer(props: any) {

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List dense>
          <ListItemLink to="/trunks" primary="Trunks" icon={<InboxIcon />} />
          <ListItemLink to="/e164" primary="E.164" icon={<InboxIcon />} />
        </List>
      </Box>
    </Drawer>
  );
}



