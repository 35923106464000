import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListTrunkItemFragment = { __typename?: 'Endpoint', id: string, dnsNameType?: Types.DnsNameType | null, transportProtocol: string, uri?: string | null, username?: string | null, password?: string | null, name?: string | null, billingId?: string | null, tenantName?: string | null, contacts: { __typename?: 'ContactConnection', items: Array<{ __typename?: 'Contact', id: string, contact?: string | null, instance?: string | null, regId?: number | null, received?: string | null, socket?: string | null, userAgent?: string | null, callid?: string | null, cseq?: number | null, version: number, createdAt?: any | null, updatedAt?: any | null, expiresAt?: any | null } | null> } };

export type ListTrunksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListTrunksQuery = { __typename?: 'Query', endpoints: { __typename?: 'EndpointConnection', items: Array<{ __typename?: 'Endpoint', id: string, dnsNameType?: Types.DnsNameType | null, transportProtocol: string, uri?: string | null, username?: string | null, password?: string | null, name?: string | null, billingId?: string | null, tenantName?: string | null, contacts: { __typename?: 'ContactConnection', items: Array<{ __typename?: 'Contact', id: string, contact?: string | null, instance?: string | null, regId?: number | null, received?: string | null, socket?: string | null, userAgent?: string | null, callid?: string | null, cseq?: number | null, version: number, createdAt?: any | null, updatedAt?: any | null, expiresAt?: any | null } | null> } } | null> } };

export const ListTrunkItemFragmentDoc = gql`
    fragment ListTrunkItem on Endpoint {
  id
  dnsNameType
  transportProtocol
  uri
  username
  password
  name: tagValue(key: "Name")
  billingId: tagValue(key: "billingId")
  tenantName: tagValue(key: "tenantName")
  contacts {
    items {
      id
      contact
      instance
      regId
      received
      socket
      userAgent
      callid
      cseq
      version
      createdAt
      updatedAt
      expiresAt
    }
  }
}
    `;
export const ListTrunksDocument = gql`
    query ListTrunks {
  endpoints {
    items {
      ...ListTrunkItem
    }
  }
}
    ${ListTrunkItemFragmentDoc}`;

/**
 * __useListTrunksQuery__
 *
 * To run a query within a React component, call `useListTrunksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrunksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrunksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTrunksQuery(baseOptions?: Apollo.QueryHookOptions<ListTrunksQuery, ListTrunksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTrunksQuery, ListTrunksQueryVariables>(ListTrunksDocument, options);
      }
export function useListTrunksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTrunksQuery, ListTrunksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTrunksQuery, ListTrunksQueryVariables>(ListTrunksDocument, options);
        }
export type ListTrunksQueryHookResult = ReturnType<typeof useListTrunksQuery>;
export type ListTrunksLazyQueryHookResult = ReturnType<typeof useListTrunksLazyQuery>;
export type ListTrunksQueryResult = Apollo.QueryResult<ListTrunksQuery, ListTrunksQueryVariables>;