import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';

import TrunkList from './TrunkList';
import CreateTrunk from './CreateTrunk';

import { Routes, Route, Link } from "react-router-dom";
import { Button, Container, Box, Toolbar } from '@mui/material';

import FluentStreamAppBar from './FluentStreamAppBar';
import FluentStreamDrawer from './FluentStreamDrawer';
import E164List from './E164List';

function Home() {
  return <div>
    <Link to="trunks">Trunks</Link>
  </div>
}

function App() {
  return <>
    <Box sx={{ display: 'flex' }}>
      <FluentStreamAppBar />
      <FluentStreamDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 3, }}>
        <Toolbar />
        <Container>
          <Box sx={{ my: 2 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="trunks" element={<TrunkList />} />
              <Route path="trunks/create" element={<CreateTrunk />} />
              <Route path="e164" element={<E164List />} />
            </Routes>
          </Box>
        </Container>
      </Box>
    </Box>
  </>;
}


function Wrapper({ children }: any) {

  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Oops... {error.message}</div>;
  }
  else if (!isAuthenticated) {
    return <Button onClick={loginWithRedirect}>
      Log In
    </Button>
  }

  return <>
    <AuthorizedApolloProvider>
      {children}
    </AuthorizedApolloProvider>
  </>

}

function EntryPoint() {

  return (
    <Wrapper>
      <App />
    </Wrapper>
  )

}


export default EntryPoint;
