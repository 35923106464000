import { gql, useQuery } from "@apollo/client";
import { Refresh } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { Button, ButtonGroup, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";


const LIST = gql`
query ListPhoneNumbers {
  phoneNumbers {
    items {
      id
      e164
      logicalTarget
      target {
        destination {
          __typename
          ... on Endpoint {
            uri
          }
          ... on TelUri {
            number
          }
        }
        failover {
          __typename
          ... on Endpoint {
            uri
          }
          ... on TelUri {
            number
          }
        }
      }
      createdAt
    }
  }
}
`;

function DataList({ loading, data }: any) {
  if (loading) {
    return <></>
  }
  const handleRefresh = () => { };
  return <>
    <Box role="presentation">
      <ButtonGroup disableElevation variant="contained">
        <Button disableElevation variant="contained">
          <Refresh onClick={handleRefresh} />
        </Button>
        {/* <ItemMenu items={selectedValues} /> */}
      </ButtonGroup>
    </Box>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Number</TableCell>
          <TableCell>Target</TableCell>
          <TableCell>Fallback</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((e: any) => <TableRow key={e.id}>
          <TableCell>{e.e164}</TableCell>
          <TableCell>{e.target?.destination?.uri}</TableCell>
          <TableCell>{e.target?.failover?.number}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table >
  </>;
}

function ListWithData() {

  const { loading, error, data, } = useQuery(LIST);

  if (error) {
    return <Typography color="red">Network Error: {error?.message}</Typography>
  }


  return <>
    <DataList loading={loading} data={data?.phoneNumbers?.items} />
  </>

}




export default function () {
  return <ListWithData />
}
